@import 'src/scss/variables';

.template {
  display: flex;
  height: 100%;
  min-width: 1280px;
  position: relative;
}

.break {
  border-right: 1px solid $neutrals_3;
  border-radius: 1px;
}
