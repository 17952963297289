.content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 350px;
    gap: 16px;
  }

  p {
    font-size: 13px;
    margin: 10px 0;
  }
}

.template {
  display: flex;
  height: 100%;
  min-width: 1280px;
  position: relative;
}
