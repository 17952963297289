@import '@cognitiv/cassiopeia-ui/dist/luna';

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/public/getty-images-login.png');
}

.login {
  width: 420px;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: $background-secondary;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    img {
      height: 40px;
      width: auto;
    }
  }
  // fix the background color for the input that causes 1password
  input[data-com-onepassword-filled='light'],
  input[data-com-onepassword-filled='dark'] {
    height: 100% !important;
    box-shadow: 0 0 0 1000px $background-field-normal inset;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
    }
  }

  .text {
    margin-top: 32px;
  }
}

.error {
  display: flex;
  justify-content: center;
  font-size: 13px;
  margin: 16px 0 0;
  box-sizing: border-box;
  font-weight: 400;
  color: $red;
}
