.page {
  width: 100%;
  height: 100%;
  overflow: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  gap: 16px;
  height: 100%;
}
