.content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50px;
}

.slim {
  padding-left: 150px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
