@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import '@cognitiv/cassiopeia-ui/dist/luna';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-size: 13px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: $background-primary;
  color: $text-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: $background-tertiary-normal;
}

::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

div.tooltip {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
  border-radius: 8px;
  background-color: $white;
  color: $grey_650;
  position: absolute;
  width: 208px;
  pointer-events: none;
  z-index: 2;
}

// firefox does not support changing the border-radius of the scrollbar thumb,
html {
  scrollbar-width: thin;
  scrollbar-color: $background-tertiary-normal transparent;
}
