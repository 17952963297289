@import '@cognitiv/cassiopeia-ui/dist/luna';

.navigation {
  display: flex;
  align-items: center;
  background-color: $background-side;
  min-height: 50px;
  max-height: 50px;
  box-sizing: border-box;
  padding: 0 16px;
  gap: 16px;
  width: 100%;
  z-index: 10;
  color: $text-invert-normal;

  .grow {
    flex-grow: 1;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  color: $text-sidebar-normal;
  cursor: pointer;
  white-space: nowrap;
  max-width: 500px;

  a,
  p,
  svg {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    line-height: 50px;
  }

  &:hover {
    a,
    p,
    svg {
      color: $text-sidebar-hover;
    }

    &.static {
      cursor: initial;
      p {
        color: $text-sidebar-normal !important;
      }
    }
  }

  &:active {
    a,
    p,
    svg {
      color: $text-sidebar-pressed;
    }
  }

  a,
  p,
  svg {
    color: $text-sidebar-normal;
    text-decoration: none;
  }

  p {
    margin: 0;
  }
}

.breadcrumb_name {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: flex-end;
  gap: 8px;
  white-space: nowrap;

  p {
    margin: 0;
  }
}

.icon:last-of-type {
  display: none;
}
